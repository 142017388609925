<template>
  <div id="shopcart">
      购物车
  </div>
</template>

<script>
export default {
    name: 'Shopcart'
}
</script>

<style scoped>

</style>